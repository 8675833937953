import React from "react"
import { graphql, StaticQuery } from "gatsby"

// import Layout from "../components/layout"
import SEO from "../components/seo"
import GlimpseIndex from "../components/glimpseIndex"

import "../utils/normalize.css"
import "../utils/css/screen.css"
import ImmersionLayout from "../layouts/immersionLayout"
// import LoopingVideo from "../videos/skylapse_dark_trimmed.mp4"
class BlogIndex extends React.Component {
  render() {
    const siteTitle = this.props.data.site.siteMetadata.title
    const posts = this.props.data.allWordpressPost.edges
    const shop_page = this.props.data.wordpressPage

    return (
      <ImmersionLayout title={siteTitle} hideLogo={true}>
        <SEO title="HANDSMADE" keywords={[`oakland`, `filmmakers`]} />

        {/*{data.site.siteMetadata.description && (*/}
        {/*  <header className="page-head">*/}
        {/*    <h2 className="page-head-title">*/}
        {/*      {data.site.siteMetadata.description}*/}
        {/*    </h2>*/}
        {/*  </header>*/}
        {/*)}*/}
        <GlimpseIndex name="index" posts={posts} fixedItem={shop_page} />
      </ImmersionLayout>
    )
  }
}

export default BlogIndex

export const postQuery = graphql`
  fragment PostACFFields on wordpress__POST {
    id
    title
    date(formatString: "MM.DD.YYYY")
    slug
    sticky
    categories {
      name
    }
    acf {
      redirect
      thumbnail {
        localFile {
          childImageSharp {
            fluid(maxWidth: 600) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
    allWordpressPost(
      filter: { status: { ne: "gatsby_placeholder" } }
      sort: { fields: [sticky, date], order: DESC }
      limit: 200
    ) {
      edges {
        node {
          id
          title
          date(formatString: "MM.DD.YYYY")
          slug
          sticky
          categories {
            name
          }
          acf {
            redirect
            page_video
            thumbnail {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 900, maxHeight: 800, cropFocus: CENTER) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }

    wordpressPage(slug: { eq: "hands" }) {
      id
      title
      date(formatString: "MM.DD.YYYY")
      slug
      acf {
        thumbnail {
          localFile {
            childImageSharp {
              fluid(maxWidth: 700) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`

//
// export default props => (
//   <StaticQuery
//     query={indexQuery}
//     render={data => (
//       <BlogIndex location={props.location} props data={data} {...props} />
//     )}
//   />
// )
