import React from "react"
import GlimpseCard from "./glimpseCard"

class GlimpseIndex extends React.Component {
  constructor(props) {
    super(props)
    let order = this.generateShuffleOrder()
    this.state = { order: [] }
  }

  tick() {
    this.reshufflePosts()
    // this.setState({
    //   date: new Date()
    // });
  }

  reshufflePosts() {
    let order = this.generateShuffleOrder()
    this.setState({ order: order })
  }

  generateShuffleOrder() {
    let result = Array.from(this.props.posts.keys())
    result = this.shuffleArray(result)
    result = result.slice(0, this.props.fixedItem ? 8 : 9)
    return result
  }

  shuffleArray(array) {
    return array
      .map(a => [Math.random(), a])
      .sort((a, b) => a[0] - b[0])
      .map(a => a[1])
  }

  componentDidMount() {
    // this.timerID = setInterval(
    //   () => this.tick(),
    //   25000
    // );
    this.reshufflePosts()
  }

  componentDidUpdate() {
    // this.reshufflePosts();
  }

  // componentWillUnmount() {
  //   // clearInterval(this.timerID);
  // }

  render() {
    const fixedItem = this.props.fixedItem
    let postCounter = 0
    // let order = this.generateShuffleOrder()
    // const posts = order.map(idx => this.props.posts[idx])
    const posts = this.state.order.map(idx => this.props.posts[idx])
    if (!posts.length) {
      return <div className="post-feed"></div>
    }
    // Add in shop at fixed position
    posts.splice(4, 0, { node: fixedItem })
    // console.log(this.state.order)
    // console.log(posts)

    return (
      <div className="post-feed">
        {posts.map(({ node }) => {
          postCounter++

          const card = (
            <GlimpseCard
              key={node.slug}
              count={postCounter}
              node={node}
              postClass={`post`}
              type={postCounter === 5 ? "page" : "post"}
            ></GlimpseCard>
          )

          // if (postCounter === 5 && fixedItem) {
          //   return [fixed, card]
          // } else {
          return card
          // }
        })}
      </div>
    )
  }
}

export default GlimpseIndex
