import React from "react"
import InOutLink from "./InOutLink"

export default props => {
  const linkPrefix = (props.type === "post" && "/projects") || ""
  const isHands = props.type !== "post"
  const targetURL =
    props.node.acf.redirect || `${linkPrefix}/${props.node.slug}`
  const imageSrc =
    props.node.acf &&
    props.node.acf.thumbnail &&
    props.node.acf.thumbnail.localFile &&
    props.node.acf.thumbnail.localFile.childImageSharp
      ? props.node.acf.thumbnail.localFile.childImageSharp.fluid.src
      : ""

  return (
    <article
      className={`post-card  ${props.postClass} ${
        props.node.acf.thumbnail ? `with-image` : `no-image`
      } ${isHands ? `  ` : `fade-in-slow`} `}
      style={
        imageSrc && {
          backgroundImage: `url(${imageSrc})`,
        }
      }
    >
      <InOutLink
        to={isHands ? "https://shop.handsmade.xyz" : targetURL}
        className="post-card-link"
        target={isHands ? "" : undefined}
      >
        {" "}
      </InOutLink>
    </article>
  )
}
